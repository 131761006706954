import { Box } from '@mui/material'
import React from 'react'

export const Title = (props) => {
  return (
    <>
      <Box
        component={props.component}
        sx={{
          fontSize: ['24px', '32px', '38px', '44px'],
          fontWeight: 600,
          textAlign: 'center',
          color: '#000',
          py: 'var(--section-spacing-y)',
          ...(props.sx || {}),
        }}
      >
        {props.title}
      </Box>
    </>
  )
}

export default Title
