import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'
import { Footer, Header, SEO, useI18next } from '../components'
import OurTech from '../components/OurTech'
import Title from '../components/Title'

const downloadList = [
  { icon: 'icon', name: 'App Store' },
  { icon: 'google', name: 'Google play' },
  { icon: 'huawei', name: 'AppGallery' },
]

export default (props) => {
  const {} = useTranslation()
  const theme = useTheme()
  const { direction } = theme
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 600px)')

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.technology.title}
        description={seoLocale.technology.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Technology innovations in logistics, Latest technologies in logistics, Logistics technology software, Logistics technology apps'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/technology' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/10fd9829c96e6f55de9f785095486bc8/dc406/tech_2.webp'
        />
        <meta property='og:title' content="iMile's Technology innovations in logistics" />
        <meta
          property='og:description'
          content="Take a look at our latest technologies. We use in-house apps and technologies to enhance our client's and customers' experience."
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          height: ['100vh', '100vh', '650px', '790px'],
          background: '#095ef7',
          '.bg-img': {
            position: 'absolute',
            ...(direction === 'rtl' ? { right: ['50%'] } : { left: ['50%'] }),
            top: ['64%', '64%', '170px'],
            width: ['299px', '451px', '442px', '536px', '550px'],
            height: ['294px', '446px', '435px', '528px', '542px'],
            transform: [
              `translate(${direction === 'rtl' ? '50%' : '-50%'}, -50%)`,
              `translate(${direction === 'rtl' ? '50%' : '-50%'}, -50%)`,
              'unset',
            ],
          },
        }}
      >
        <img
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/tech/tech_2.png'
          alt='About iMile Delivery'
          className='bg-img'
        />
        {/* <StaticImage
          src='../../static/images/tech/tech_2.png'
          alt='About iMile Delivery'
          objectFit='cover'
          loading='lazy'
          placeholder='blurred'
          className='bg-img'
        /> */}
        <Box
          sx={{
            position: 'absolute',
            top: ['25%', '25%', '41%', '38%'],
            transform: 'translate(0, -40%)',
            left: direction === 'ltr' ? ['16px', '80px'] : 'unset',
            right: direction === 'rtl' ? ['16px', '80px'] : 'unset',
            color: '#fff',
            marginInlineStart: 'auto',
            '.tit': {
              fontSize: 'var(--slogan-tit-font-size)',
              fontWeight: 800,
              mb: '10px',
              whiteSpace: 'pre-line',
            },
            '.desc': {
              letterSpacing: '1px',
              fontSize: 'var(--slogan-desc-font-size)',
              whiteSpace: 'pre-line',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* iMile’s innovation continues */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
        }}
      >
        <Title title={pageLocale.title1} component='h2' />
        <Box
          sx={{
            fontSize: ['14px', '20px', '21px'],
          }}
        >
          {pageLocale.content1}
        </Box>
        <Box
          sx={{
            fontWeight: 500,
            fontSize: ['16px', '26px', '30px'],
            lineHeight: 0,
            py: ['30px', '60px'],
          }}
        >
          {pageLocale.content1_1}
        </Box>
      </Box>

      <Box
        sx={{
          px: [0, 0, 'var(--page-px)'],
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          '.img': {
            height: ['255px', '660px'],
          },
        }}
      >
        <img
          alt='Technology Used by iMile Delivery'
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/tech_1.png'
          className='img'
        />
        {/* <StaticImage
          alt='Technology Used by iMile Delivery'
          src='../../static/images/tech/tech_1.png'
          objectFit='cover'
          loading='lazy'
          placeholder='blurred'
          className='img'
        /> */}
      </Box>

      {/* our tech */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title title={pageLocale.title2} component='h2' />
        <OurTech locales={pageLocale} />
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: ' 100vw',
          minHeight: ['176px', '240px'],
          px: 'var(--page-px)',
          color: '#000',
          background: '#e6e6ef',
          fontWeight: 600,
          fontSize: ['16px', '28px'],
          mt: ['45px', '90px'],
          p: '20px',
        }}
      >
        {pageLocale.content4}
      </Box>
      <Box
        sx={{
          display: [, 'flex'],
          alignItems: 'center',
          px: 'var(--page-content-px)',
          py: 'var(--section-spacing-y)',
        }}
      >
        <Box
          sx={{
            '.img': {
              height: ['150px', '372px'],
            },
          }}
        >
          <Title
            component='h2'
            title={pageLocale.title3}
            sx={{
              textAlign: ['center', 'left'],
              pt: '0px',
              pb: ['var(--section-spacing-y)', 'calc(var(--section-spacing-y) - 50px)'],
            }}
          />
          {!isPc && (
            <img
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/tech/tech_2.png'
              alt={`iMile's Self-Driving Vehicle`}
              className='img'
              style={{ float: 'right' }}
            />
          )}

          <Box
            sx={{
              fontSize: ['14px', '20px', '24px'],
              color: '#5b5c73',
            }}
          >
            {pageLocale.content5}
            <p>{pageLocale.content5_1}</p>
          </Box>
        </Box>
        <Box
          component='div'
          sx={{
            // width: ['300px', '400px'],
            flexShrink: 0,
            float: 'left',
            clear: 'both',
            align: 'center',
            width: ['40%', '30%'],
            marginInlineStart: ['10px', '30px'],
            display: 'block',
            '.img': {
              height: ['150px', '372px'],
              objectFit: 'contain',
            },
          }}
        >
          {isPc && (
            <img
              alt={`iMile's Self-Driving Vehicle`}
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/tech/tech_2.png'
              className='img'
            />
            // <StaticImage
            //   alt={`iMile's Self-Driving Vehicle`}
            //   src='../../static/images/tech/tech_2.png'
            //   objectFit='contain'
            //   loading='lazy'
            //   placeholder='blurred'
            //   className='img'
            // />
          )}
        </Box>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["technology"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
