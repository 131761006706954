import { Box, Grid } from '@mui/material'
import { useLocation } from '@reach/router'
import { useI18next } from 'gatsby-plugin-react-i18next'
import React from 'react'

interface Props {
  locales: Record<string, any>
}

export default (props: Props) => {
  const { locales } = props
  const location = useLocation()
  const { language } = useI18next()

  const BoxWrapperSx = {
    '--width': ['255px', '320px', '340px', '370px', '400px'],
    '--height': ['285px', '340px', '398px', '428px', '458px'],
    position: 'relative',
    width: 'var(--width)',
    minHeight: 'var(--height)',
    borderRadius: ['20px', '32px'],
    bgcolor: '#fff',
    mb: ['30px'],
    mx: '10px',
    boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
    '&:after': {
      content: '""',
      position: 'absolute',
      border: '4px solid #095ef7',
      minWidth: 'var(--width)',
      minHeight: 'var(--height)',
      borderRadius: ['20px', '32px'],
      left: '10px',
      top: '-9px',
      zIndex: -1,
    },
    '.img': {
      position: 'absolute',
    },
  }

  const Title = (tit) => {
    return (
      <Box
        component={'h3'}
        sx={{
          position: 'absolute',
          top: [30, 40],
          mx: '10px',
          fontSize: language === 'es-MX' ? [14, 18, 20, 22, 24] : [19, 22, 24, 26, 30],
          fontWeight: 600,
          color: '#000417',
          textAlign: 'center',
        }}
      >
        {tit}
      </Box>
    )
  }
  const Content = (desc?) => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: [130, 160],
          fontSize: [14, 18, 18, 21],
          fontWeight: 400,
          color: '#5b5c73',
          textAlign: 'center',
          whiteSpace: 'pre-line',
        }}
      >
        {desc}
      </Box>
    )
  }

  return (
    <Grid container columns={{ xs: 10, sm: 8, md: 12, lg: 12, xl: 12 }}>
      {locales.content3.map((item, i) => (
        <Grid
          item
          xs={10}
          sm={4}
          md={6}
          lg={4}
          xl={4}
          key={i}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box
            sx={{
              ...BoxWrapperSx,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {Title(item.split('&&')[0])}
              {Content(item.split('&&')[1])}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
